<template>
    <div>
      <b-button
        variant="primary"
        :to="`/contacts/${trackerid}`"
        size="md"
        class="mb-1"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          class="mr-50"
        />
        <span class="align-middle">{{$t('Labels.Back')}}</span>
      </b-button>
      <b-row>
        <b-col cols="12">
            <b-card class="">
              <div class="container-options">
                <b-button :to="`/contacts/edit/${trackerid}/${uuid}`" class="btn-icon" variant="outline-primary mr-1">
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button @click="confirmDelete(uuid)" class="btn-icon" variant="outline-primary">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <gridPrincipalItem
                img="contact"
                :name="response.name"
                :description="$t('Labels.Name')"
              />
              <hr>
              <valueItem
                :value="response.phone"
                :description="$t('Labels.Phone')"
                img="phone"
              />
              <valueItem
                :value="response.email"
                :description="$t('Email')"
                img="email"
              />
              <valueItem
                :value="getRelationshipLabel(response.relationship)"
                :description="$t('Labels.Relationship')"
                img="relationship"
              />
            </b-card>
        </b-col>
      </b-row>
    </div>
</template>
<script>
import gridPrincipalItem from '@core/spore-components/gridPrincipalItem/gridPrincipalItem.vue'
import valueItem from '@core/spore-components/valueItem/valueItem.vue'
import request from '@/libs/request/index'
import {
  BCard,
  BRow,
  BCol,
  BButton
} from 'bootstrap-vue'

const DATA = {
  code: 200,
  data: {
    uuid: 'cdfbacef-3540-41b5-aae3-448bcd330df4',
    name: 'Juan Pérez',
    phone: '3331455226',
    email: 'juan.perez@gmail.com',
    relationship: 'Padre',
    priority_notification: '1',
    created_at: '2021-08-20 17:29:11'
  },
  msg: 'Ok'
}
export default {
  components: {
    gridPrincipalItem,
    valueItem,
    BCard,
    BRow,
    BCol,
    BButton
  },

  data () {
    return {
      response: null,
      uuid: this.$route.params.uuid,
      trackerid: this.$route.params.trackerid,
      DATA
    }
  },

  methods: {
    async getResponse () {
      // this.response = DATA.data
      const params = {
        url: `tracker_contacts/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        this.response = data.data.data
      })
    },
    getRelationshipLabel (value) {
      const relationship = [
        this.$t('Labels.Parents'),
        this.$t('Labels.Childrens'),
        this.$t('Labels.BroSis'),
        this.$t('Labels.HusWife'),
        this.$t('Labels.GrandParents'),
        this.$t('Labels.Grandchildren'),
        this.$t('Labels.Cousin'),
        this.$t('Labels.Nephews'),
        this.$t('Labels.Uncles'),
        this.$t('Labels.SonInLaw'),
        this.$t('Labels.ParentsInLaw'),
        this.$t('Labels.BroInLaw'),
        this.$t('Labels.NoParentship'),
        this.$t('Labels.DistantFamily'),
        this.$t('Labels.None')
      ]
      return relationship[parseInt(value) - 1] || ''
    },
    confirmDelete (uuid) {
      this.$swal({
        title: this.$t('Messages.ConfirmDeleteContact'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracker_contacts/delete/${uuid}`,
            method: 'DELETE'
          }
          await request(params).then(response => {
            this.$router.push(`/contacts/${this.trackerid}`)
          })
        }
      })
    }
  },
  created () {
    this.getResponse()
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Detail.scss";
</style>
